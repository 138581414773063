import { React, createContext, useContext, useState } from 'react'

const defaultState = {
  lang: 'pl',
  setLang: () => {},
  toggleLang: () => {},
}

const LangContext = createContext(defaultState)

export const LangProvider = ({ children }) => {
  const [lang, setLangState] = useState('pl')

  const setLang = (langToSet) => {
    setLangState(langToSet)
  }

  // const setLangNoLocalSave = (langToSet) => {
  //   setLangState(langToSet)
  // }

  const toggleLang = () => {
    const newLang = lang === 'pl' ? 'en' : 'pl'
    setLangState(newLang)
    localStorage.setItem('lang', JSON.stringify(newLang))
  }

  return (
    <LangContext.Provider value={{ lang, toggleLang, setLang }}>
      {children}
    </LangContext.Provider>
  )
}

export default LangContext

export const useLangContext = () => useContext(LangContext)
